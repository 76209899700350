import {React,useState,useEffect} from 'react'
import './topbar.css'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import MopedIcon from '@mui/icons-material/Moped';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HelpIcon from '@mui/icons-material/Help';
import QuizIcon from '@mui/icons-material/Quiz';
import { useNavigate } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { Auth, signInWithEmailAndPassword } from 'firebase/auth'
import Axios from 'axios'
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import TimerIcon from '@mui/icons-material/Timer';


const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
  

export default function Topbar() {
    const navigate =  useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);
    const [anchorE3, setAnchorE3] = useState(null);
    const [email,setEmail] = useState('');
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorE2);
    const open3 = Boolean(anchorE3);
    const [pendingOrders,setPendingOrders] = useState([])
    const [delayed,setDelayed] = useState([]);


    useEffect(() =>{
      Axios.get('https://api.easybox.ke/api/getPendingOrders').then((res)=>{
          setPendingOrders(res.data)
          })
     
    },[])

    // useEffect(() =>{
    //   if(delayed.length > 0){
    //     Axios.post('https://nod3.brane360.com/webhook/2165985c-a01a-4639-81f2-82574b60d9de',{
    //         noOFOrders:delayed.length
    //         })
    //   }
    // },[delayed])

    useEffect(() =>{
      Axios.get('https://api.easybox.ke/api/getDelayed').then((res)=>{
          setDelayed(res.data)
          
          
          })
    },[])


    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorE2(event.currentTarget);
      };
    
    const handleClick3 = (event) => {
        setAnchorE3(event.currentTarget);
      };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorE2(null);
      };
      const handleClose3 = () => {
        setAnchorE3(null);
      };

      const callOrders = () =>{
        navigate('/orders')
        setAnchorE2(null);
    }
    const callDashboard = () =>{
        navigate('/home')
        setAnchorE2(null);
    }
    const callStores = () =>{
      navigate('/stores')
      setAnchorE2(null);
  }
  const callRiders = () =>{
    navigate('/riders')
    setAnchorE2(null);
}
const callUsers = () =>{
  navigate('/users')
  setAnchorE2(null);
}
const callPayments = () =>{
  navigate('/payments')
  setAnchorE2(null);
}
const callReports = () =>{
  navigate('/reports')
  setAnchorE2(null);
}
const callSettings = () =>{
  navigate('/settings')
  setAnchorE2(null);
}
const callHelp = () =>{
  navigate('/help')
  setAnchorE2(null);
}
const callFaq = () =>{
  navigate('/faqs')
  setAnchorE2(null);
}

const signout = () =>{
  signOut(auth).then(()=>{
    navigate('/');
  }).catch(error=>console.log(error))
  
}
var username = '';


useEffect(() =>{
  auth.onAuthStateChanged((user) =>{
    if(user){
        
       setEmail(user.email)
        
    }
   
  })
  
  
 
},[])

  
  return (
    <div className='topbarWrapper'>
        <div className="topbarLeft">
           
            <div className="mobileMenu">
            <MenuIcon onClick={handleClick2} style={{fontSize:'30px',marginLeft:'3%'}} />
    
    <StyledMenu
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorE2}
      open={open2}
      onClose={handleClose2}
    >
      <MenuItem onClick={callDashboard} disableRipple>
        <DashboardIcon />
        Dashboard
      </MenuItem>
      <MenuItem onClick={callOrders} disableRipple>
        <ShoppingCartIcon />
        Orders
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={callStores} disableRipple>
        <StoreIcon />
        Stores
      </MenuItem>
      <MenuItem onClick={callRiders} disableRipple>
        <MopedIcon />
        Riders
      </MenuItem>
      <MenuItem onClick={callUsers} disableRipple>
        <SupervisorAccountIcon />
        Users
      </MenuItem>
      <MenuItem onClick={callReports} disableRipple>
        <AssessmentIcon />
        Reports
      </MenuItem>
      <MenuItem onClick={callSettings} disableRipple>
        <SettingsIcon />
        Settings
      </MenuItem>
      <MenuItem onClick={callHelp} disableRipple>
        <HelpIcon />
        Help
      </MenuItem>
      <MenuItem onClick={callFaq} disableRipple>
        <QuizIcon />
        FAQs
      </MenuItem>
      

    </StyledMenu>
            </div>
           
            <img src="/assets/easyboxLogo.png" className='logo' alt="" />

        </div>
        
        <div className="topbarRight">
            <div className="notification">
            <NotificationsIcon onClick={handleClick3} style={{color:'grey'}} />
              <p  className='notificationNumber'>{pendingOrders.length +  delayed.length}</p>
            </div>
            <p className='adminTitle'>Admin</p>
            <img src="/assets/accountIcon.png" className='accountImg' alt="" />
        <ArrowDropDownIcon onClick={handleClick} style={{fontSize:'30px',marginLeft:'3%'}} />
    
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
       
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={callHelp} disableRipple>
          <ReportIcon />
          Report an Issue
        </MenuItem>
        <MenuItem onClick={signout} disableRipple>
          <LogoutIcon />
          Log out
        </MenuItem>
      </StyledMenu>


        <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorE3}
        open={open3}
        onClose={handleClose3}
      >
       
      
        {pendingOrders.map((val) => {
                   
                   return(
                    <p  onClick={callOrders} className='pending'><NewReleasesIcon sx={{color:'green',fontSize:'15px'}} /><strong> New order</strong> at {val.store}</p>
                     
                   )
               })}
         {delayed.map((val) => {
           
           var ms = val.TotalTime;
           var d = new Date(1000*Math.round(ms/1000)); // round to nearest second
           function pad(i) { return ('0'+i).slice(-2); }
           var str = d.getUTCHours()+'hrs' + ': ' + pad(d.getUTCMinutes()) + 'min' + ': ' + pad(d.getUTCSeconds())+'sec';
                   
                   return(
                    <p  onClick={callOrders} className='pending'><TimerIcon sx={{color:'red',fontSize:'15px'}} /><strong> #Order </strong>{val.orderNo} - {str}</p>
                     
                   )
               })}
      </StyledMenu>
            
            

        </div>

    </div>
  )
}
