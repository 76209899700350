import {React,useState,useEffect} from 'react'
import './sidebar.css'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import MopedIcon from '@mui/icons-material/Moped';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import QuizIcon from '@mui/icons-material/Quiz';
import PaidIcon from '@mui/icons-material/Paid';
import { auth } from '../../firebase';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useNavigate } from "react-router-dom";



export default function Sidebar() {
  const [email,setEmail] = useState('');
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  useEffect(() =>{
    auth.onAuthStateChanged((user) =>{
      if(user){
          
         setEmail(user.email)
          
      }
     
    })
  },[])


    const navigate =  useNavigate();

    const callOrders = () =>{
        navigate('/orders')
    }
    const callDashboard = () =>{
      if(email == 'admin@easybox.ke'){
        navigate('/home')
      }
      else{
        handleClick();
      }

        
    }
    const callStores = () =>{
      if(email == 'admin@easybox.ke'){
        navigate('/stores')
      }
      else{
        handleClick();
      }
      
  }
  const callRiders = () =>{
    if(email == 'admin@easybox.ke'){
      navigate('/riders')
    }
    else{
      handleClick();
    }
  
}
const callUsers = () =>{
  if(email == 'admin@easybox.ke'){
    navigate('/users')
  }
  else{
    handleClick();
  }
 
}
const callPayments = () =>{
  if(email == 'admin@easybox.ke'){
    navigate('/payments')
  }
  else{
    handleClick();
  }
  
}
const callReports = () =>{
  if(email == 'admin@easybox.ke'){
    navigate('/reports')
  }
  else{
    handleClick();
  }
  
}
const callSettings = () =>{
  navigate('/settings')
}
const callHelp = () =>{
  navigate('/help')
}
const callFaq = () =>{
  navigate('/faqs')
}
    
  return (
    <div className='sidebarWrapper'>
       <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          You do not have permission!
        </Alert>

        
      </Snackbar>
        <br />
      <div onClick={callDashboard} className="menuItem">
        <DashboardIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Dashboard</button>
      </div>
      <div className="menuItem" onClick={callOrders}>
        <ShoppingCartIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Orders</button>
      </div>
      <div className="menuItem" onClick={callStores}>
        <StoreIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Stores</button>
      </div>
      <div className="menuItem" onClick={callRiders}>
        <MopedIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Riders</button>
      </div>
      <div className="menuItem" onClick={callUsers}>
        <SupervisorAccountIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Users</button>
        
      </div>
      <div className="menuItem" onClick={callPayments}>
        <PaidIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Payment</button>
        
      </div>
      <div className="menuItem" onClick={callReports}>
        <AssessmentIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Reports</button>
        
      </div>
      <hr />
      <div className="menuItem" onClick={callSettings}>
        <SettingsIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Settings</button>
        
      </div>
      <div className="menuItem" onClick={callHelp}>
        <HelpIcon style={{color:'grey',fontSize:'27px'}} />
        <button >Help</button>
        
      </div>
      <div className="menuItem" onClick={callFaq}>
        <QuizIcon style={{color:'grey',fontSize:'27px'}} />
        <button >FAQs</button>
        
      </div>
      <p className='rights'>Developed and maintained by <a href='https://www.uidevlabs.com'>Uidevlabs Technologies</a></p>
      

    </div>
  )
}
