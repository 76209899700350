import {React, useState, useEffect} from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Topbar from '../../components/topbar/Topbar'
import './user.css';
import { Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firebase';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function Users() {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)
    const [username, setUsername] = useState('');
    const [useremail,setUseremail] = useState('');
    const [userphone, setUserphone] = useState('');
    const [userrole, setUserrole]=useState("")
    const [users,setUsers] = useState([]);
    const [thisUser, setThisUser] = useState('');
    const [error,setError] = useState('');


    useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getUsers').then((res)=>{
            setUsers(res.data)
            })
       
      },[])

    const handleClose = () => {
        setError('')
        setOpen(false);
    }

    const handleOpen = (event) => {
      
        setOpen(true);
      }

      const handleClose2 = () => {
        setOpen2(false);
    }

    const handleOpen2 = (event) => {
      
        setOpen2(true);
      }


      const handleClose3 = () => {
        setOpen3(false);
    }

    const handleOpen3 = (event) => {
        setThisUser(event.target.value)
        setOpen3(true);
      }

      const handleClose4 = () => {
        setOpen4(false);
    }

    const handleOpen4 = (event) => {
        setThisUser(event.target.value)
        setOpen4(true);
      }


      const submitUser = () =>{
        if(useremail == '' || username == '' || userphone == '' || userrole == ''){
            setError('All fields are mandatory')
        }
        else{
        Axios.post('https://api.easybox.ke/api/insertUser',{
            username : username,
            useremail : useremail,
            userphone : userphone, 
            userrole : userrole
        })
        var password = 'easybox123';
        createUserWithEmailAndPassword(auth,useremail,'easybox123')
    
        
        handleClose()
        window.location.reload(false);
    }
    };

    function suspendUser() {
        // setOrderId(event.target.value)
        // console.log(event.target.value)
      
        Axios.put('https://api.easybox.ke/api/suspendUser',{
            
            
            userid:thisUser
            
        })
        handleClose3()
        window.location.reload(false);
      }

      const deleteUser = () =>{
        Axios.delete('https://api.easybox.ke/api/deleteUser?userId='+thisUser)
        handleClose4()
        window.location.reload(false);
       
    };

    var records = users.length + ' records';
  return (
    <div className='storeWrapper'>
        {/* ADD STORE*/}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling2'>
            <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 className='modalTitle'>ADD USER</h4>
            <p style={{fontSize:'12px',color:'red'}}>{error}</p>
          </Typography>

            </div>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <div className="inputField">
           <TextField onChange={(e)=> setUsername(e.target.value)} type='text' id="standard-basic2" label="User name*" variant="standard" />
           <br />
           <TextField onChange={(e)=> setUserphone(e.target.value)} type='text' id="standard-basic2" label="User phone*" variant="standard" />
           <br />
           <TextField onChange={(e)=> setUseremail(e.target.value)} type='text' id="standard-basic2" label="User Email*" variant="standard" />
           <br />
           <select className='userselect' name=""  id="" onChange={(e)=> setUserrole(e.target.value)}>
              <option >- Select User Role -</option>
              <option value="Admin">Administrator</option>
              <option value="Dispatcher">Dispatcher</option>
              <option value="Manager">Operations</option>
           </select>
           </div>
           <div className="assignButtons">
                <button onClick={submitUser} className='addStoreButton'>+ ADD</button>
                <button className='addStoreButton2' onClick={handleClose}>Close</button>
           </div>
           

            
          </Typography>
        </Box>
      </Modal>

              {/* EDIT STORE*/}
              <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling2'>
            <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 style={{fontSize:'16px',fontWeight:'500'}}>ATTENTION</h4>
          </Typography>

            </div>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <p className='tHeading'>You are no allowed to perform this action. Contact Admin for support.</p>
           <div className="assignButtons">
              
                <button className='addStoreButton2' onClick={handleClose2}>Close</button>
           </div>
           

            
          </Typography>
        </Box>
      </Modal>




       {/* SUSPEND RIDER*/}
       <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling2'>
            <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 style={{fontSize:'16px',fontWeight:'500'}}>CAUTION</h4>
          </Typography>

            </div>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <p className='tHeading'>Do you want to suspend user no# {thisUser}</p>
           <div className="assignButtons">
           <button onClick={suspendUser} className='addStoreButton'>Suspend</button>
                <button className='addStoreButton2' onClick={handleClose3}>Close</button>
           </div>            
          </Typography>
        </Box>
      </Modal>


             {/* DELETE STORE*/}
             <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling2'>
            <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 style={{fontSize:'16px',fontWeight:'500'}}>CAUTION</h4>
          </Typography>

            </div>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <p className='tHeading'>Are you sure you want to remove user no# {thisUser}</p>
           <div className="assignButtons">
           <button onClick={deleteUser} className='addStoreButton'>Remove</button>
                <button className='addStoreButton2' onClick={handleClose4}>Close</button>
           </div>            
          </Typography>
        </Box>
      </Modal>









        <Topbar />
        <div className="homeBody">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="ordersBody">
                <div className="storesTable">
                    <p className='storesTitle'>Users Management</p>
                <div className="addButton">
                    <button onClick={handleOpen} className='addStoreButton'>+ Add Rider</button>
                </div>
                
              <div className="users">
                <div className="unplannedOrders">
            <br />
            <p className='unplannedHeading'>Active Users</p>
            <div className="search">
                <p className='searchTitle'>Search:</p>
                <input type="text" className='searchInput' placeholder={records} />
            </div>
            <div className="table">
                <Table id="table-to-xls" >
                   
                <thead>
                    
                    <tr>
                        
                        <th className='tHeading'>User ID</th>
                        <th className='tHeading'>User name</th>
                        <th className='tHeading'>User Phone</th>
                        <th className='tHeading'>User Email</th>
                        <th className='tHeading'>User Role</th>
                        <th className='tHeading'>Status</th>     
                        <th className='tHeading'>Actions</th>

                       

                        
                        
                    </tr>
                   
                </thead>
               

                <tbody className='heading2'>
                {users.map((val) => {
                   
                    return(

                        <tr>
                            {/* <button onClick={handleOpen} className='tbutton'  value={val.orderNo} >{val.orderNo}</button> */}
                           <td className='tHeading2'>0{val.id}</td>
                            
                            <td className='tHeading2'>{val.username}</td>
                            <td className='tHeading2'>{val.userphone}</td>
                            <td className='tHeading2'>{val.useremail}</td>
                            <td className='tHeading2'>{val.userrole}</td>
                            <td className='tHeading2'>{val.status}</td>
                            <td>
                                <div className="actions">
                                    <Button onClick={handleOpen2} className='editBTN' startIcon={<EditIcon style={{fontSize:'15px'}}  />} >Edit</Button>
                                   <Button onClick={handleOpen3} value={val.id} className='blockBTN' startIcon={<RemoveCircleOutlineIcon style={{fontSize:'15px'}} />}>Suspend</Button> 
                                    <Button onClick={handleOpen4} value={val.id}  className='deleteBTN' startIcon={<DeleteIcon style={{fontSize:'15px'}}  />}>Delete</Button>

                                </div>

                            </td>
                           
                            
                            {/* <td className='tHeading2'>{date4}</td> */}
                            
                        </tr>
                    )
                })}
               
                </tbody>
            </Table>
            </div>
           </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}
