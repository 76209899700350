import React from 'react'

export default function Calculate() {
    const handleCalculate = async () => {
        const response = await fetch('https://api.easybox.ke/api/calculate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const data = await response.json();
            alert(data.message);
        } else {
            alert('Error updating records');
        }
    };

  return (
    <div>
            <h1>Update Orders</h1>
            <button onClick={handleCalculate}>Calculate Distances and Amounts</button>
        </div>
  )
}
