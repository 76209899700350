import {React,useEffect,useState} from 'react'
import { Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './adminme.css'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Axios from 'axios'

export default function Adminme() {
    const [distance,setDistance] = useState('')
    const [amount, setAmount] = useState('')
    const [orders,setOrders] = useState([])
    const [newDistance, setNewDistance] = useState('')
    const [newAmount, setNewAmount] = useState('')
    const [orderId, setOrderId] = useState('');
    var upt = 0;

    useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/withoutDistance').then((res)=>{
            setOrders(res.data)
            })
       
      },[upt])

    function updateDistance(event) {
        // setOrderId(event.target.value)
        console.log(event.target.value)
        upt = upt+1;
        Axios.put('https://api.easybox.ke/api/updateDistance',{
            distance : newDistance,
            amount : newAmount,
            orderId:event.target.value
            
        })
        window.location.reload(false);

      }
  return (
    <div className='adminme'>
        <Table id="table-to-xls" className="table">
                <thead>
                    <tr className="heading">
                        <th>Date</th>
                        <th>Order#</th>
                        <th>Outlet</th>     
                        <th>Area</th>
                        <th>Landmark</th>
                       
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>
                {orders.map((val) => {
                    var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.postTime)
                    return(
                        <tr>
                            <td>{date3}</td>
                            <td>{val.orderNo}</td>
                            <td>{val.store}</td>
                            <td>{val.location}</td>
                            <td>{val.landmark}</td>

                            <td>{val.status}</td>
                            <td><input type="text" placeholder='distance' onChange={(e)=>{setNewDistance(e.target.value)}} /></td>
                            <td><input type="text" placeholder='Amount' onChange={(e)=>{setNewAmount(e.target.value)}} /></td>
                            <button value={val.orderNo} onClick={updateDistance} >Update</button>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
    </div>
  )
}
