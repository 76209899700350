import {React, useState, useEffect} from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Topbar from '../../components/topbar/Topbar'
import './orders.css'
import { Table } from "react-bootstrap";
import { ListGroup, ListGroupItem, Button, Modal, ModalHeader } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import Axios from 'axios'
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

import { auth } from '../../firebase';
import Dispatch from './Dispatch';
import Transit from './Transit';
import Riderdetails from './Riderdetails';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: 'none',
//     boxShadow: 24,
//     p: 4,
//   };
export default function Orders() {
    const [currentDate, setCurrentDate] = useState(getDate());
    const [pendingOrders,setPendingOrders] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const[thisRider,setThisRider] = useState('');
    const[selectedRider,setSelectedRider] = useState("")
    const[thisOrder,setThisOrder] = useState('');
    const[trips,setTrips] = useState('')
    const[riders,setRiders] = useState([])
    const[error,setError] = useState('');
    const[processingOrders,setProcessingOrders] = useState([]);
    const[riderDetails,setRiderDetails] = useState([]);
    const[transitOrders,setTransitOrders]= useState([]);
    const[deliveredOrders,setDeliveredOrders] = useState([]);
    const[totalOrdersToday,setTotalOrdersToday] = useState([]);
    const[totalReturnedToday, setTotalReturnedToday] = useState([]);
    const[totalCancelledToday, setTotalCancelledToday] =  useState([]);
    const[prestigeOrdersToday, setPrestigeOrdersToday] = useState([]);
    const[kilimaniOrdersToday, setKilimaniOrdersToday] = useState([]);
    const[waterfrontOrdersToday, setWaterfrontOrdersToday]  = useState([]);
    const[abrahamOrders, setAbrahamOrdersToday] = useState([]);
    const[olimaOrders, setOlimaOrdersToday] = useState([]);
    const[riderByOrder,setRiderByOrder] = useState([]);
    const[email,setEmail] = useState([]);
    const[rnumber,setRnumber] = useState('');
    const[checkErr,setCheckErr] = useState('')
    const navigate =  useNavigate();

    

    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);

    useEffect(() =>{
      auth.onAuthStateChanged((user) =>{
          if(user){
              setEmail(user.email)
              
          }
        })
     
    },[])


    const toggle = (event) => {
      setThisOrder(event.target.value)
      setModal(!modal);
    };

    const toggle2 = (event) => {

      if(email !== 'admin@easybox.ke'){
      }
      else{
        setThisOrder(event.target.value)
        setModal2(!modal2);

      }
      
    };
    const toggle3 = (event) => {
      setThisRider(event.target.value)
      setModal3(!modal3);
    };

    const newOrder = () =>{
      navigate('https://www.entry.easybox.ke')
    }
  


    useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getPendingOrders').then((res)=>{
            setPendingOrders(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/riderOrders').then((res)=>{
            setRiderByOrder(res.data)
            })
       
      },[])


      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getProcessingOrders').then((res)=>{
            setProcessingOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getRiders').then((res)=>{
            setRiders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getTransitOrders').then((res)=>{
            setTransitOrders(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getDeliveredOrdersToday?startTime='+result).then((res)=>{
            setDeliveredOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getTotalOrdersToday?startTime='+result).then((res)=>{
            setTotalOrdersToday(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getReturnedOrdersToday?startTime='+result).then((res)=>{
            setTotalReturnedToday(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getCancelledOrdersToday?startTime='+result).then((res)=>{
            setTotalCancelledToday(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getPrestigeOrdersToday?startTime='+result).then((res)=>{
            setPrestigeOrdersToday(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getKilimaniOrdersToday?startTime='+result).then((res)=>{
            setKilimaniOrdersToday(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getWaterfrontOrdersToday?startTime='+result).then((res)=>{
            setWaterfrontOrdersToday(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getAbrahamOrdersToday?startTime='+result).then((res)=>{
            setAbrahamOrdersToday(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getOlimaOrdersToday?startTime='+result).then((res)=>{
            setOlimaOrdersToday(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getSelectedRider?rider='+selectedRider).then((res)=>{
            setRnumber(res.data.ridernumber)
            })
       
      },[selectedRider])


      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getSpecificRiderByEmail?email='+thisRider).then((res)=>{
            setRiderDetails(res.data)
            })
       
      },[thisRider])

      function assignRider() {
        if(selectedRider == '' || trips == ''){
            setError('Please select the rider and indicate number of trips!')
        }
        else{
        Axios.put('https://api.easybox.ke/api/assignRider',{
            rider : selectedRider,
            assignTime : Date.now().toString(),
            trips : trips,
            orderNo:thisOrder
        })
        Axios.post('https://nod3.brane360.com/webhook/2165985c-a01a-4600-81f2-82574b60d9dd',{
            orderNo: thisOrder,
            store:'Naivas Prestige',
            ridernumber:selectedRider
          })
        handleClose()
        window.location.reload(false);
    }

      }

      function dispatchOrder() {
        // setOrderId(event.target.value)
        // console.log(event.target.value)
      
        if(selectedRider == '' || trips == ''){
          setError('Please select the rider and indicate number of trips!')
      }
      else{
          Axios.put('https://api.easybox.ke/api/assignRider',{
              rider : selectedRider,
              assignTime : Date.now().toString(),
              trips : trips,
              orderNo:thisOrder
          })
          Axios.post('https://nod3.brane360.com/webhook/2165985c-a01a-4600-81f2-82574b60d9dd',{
              orderNo: thisOrder,
              store:'Naivas Prestige',
              ridernumber:selectedRider
            })
          handleClose()
          window.location.reload(false);
        }

      }


      function deliverOrder() {
        // setOrderId(event.target.value)
        // console.log(event.target.value)
      
        Axios.put('https://api.easybox.ke/api/deliverOrder',{
            
            deliveredTime : Date.now().toString(),
            
            orderNo:thisOrder
            
        })
        handleClose5()
        window.location.reload(false);

      }

      function cancelOrder() {
        // setOrderId(event.target.value)
        // console.log(event.target.value)
      
        Axios.put('https://api.easybox.ke/api/cancelOrder',{
            
            // dispatchTime : Date.now().toString(),
            orderNo:thisOrder
            
        })
        handleClose3()
        window.location.reload(false);
      }


    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${month}/${date}/${year}`;
      }
      let result = Date.parse(currentDate);

      var records = pendingOrders.length+' records';
      var records2 = processingOrders.length+' records';
      var records3 = transitOrders.length+' records';
      var records4 = deliveredOrders.length+' records';


      const handleClose = () => {
        setError('');
        setSelectedRider('');
        setTrips('');
        setOpen(false);
    }
      const handleClose2 = () => setOpen2(false);
      const handleClose3 = () => setOpen3(false);
      const handleClose4 = () => setOpen4(false);
      const handleClose5 = () => setOpen5(false);
  
      const handleOpen = (event) => {
          setThisOrder(event.target.value)
          setOpen(true);
        }
  
        const handleOpen2 = (event) => {
          setThisOrder(event.target.value)
          setOpen2(true);
        }
  
        const handleOpen3 = (event) => {
          setThisOrder(event.target.value)
          setOpen3(true);
        }
        const handleOpen4 = (event) => {
          setThisRider(event.target.value)
          setOpen4(true);
        }
        const handleOpen5 = (event) => {
            if(email == 'admin@easybox.ke' || email == 'disptach@easybox.ke'){
              setThisOrder(event.target.value)
              setOpen5(true);
            }
           
          }


  return (

    
    <div className="ordersWrapper">  
    
                
    {/* Riders modal */}
    <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 className='modalTitle'>Rider Details</h4>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          
          {riderDetails.map((val) => {
            return(
              <div>
                <p className='modalOrder'>Name: {val.ridername}</p>
                <p  className='modalOrder'>Phone: {val.ridernumber}</p>
                <p  className='modalOrder'>Email: {val.ridermail}</p>
              </div>
            )
          })}
          
            <div className='assignButtons'>
            
            <button onClick={handleClose4} className='cancelBTN' >Back</button>

            </div>
            
          </Typography>
        </Box>
      </Modal>


    
      <Topbar />
    

   
    <div className="homeBody">
        
    <div className="sidebar">
    <Sidebar />

    </div>
   
    <div className="ordersBody">
    
        
       <div className="ordersLeft">
       
       <div className="newOrdercont">
       <a className='newOrder' href='https://www.entry.easybox.ke' target='_blank'>New Consignment</a>
       </div>
           <p className='todayDate'>Today: {currentDate} </p>

          
           <div className="processingOrders">
            <br />
            <p className='unplannedHeading'>Waiting for Dispatch(Processing)</p>
            <div className="search">
                <p className='searchTitle'>Search:</p>
                <input type="text" className='searchInput' placeholder={records2} />
            </div>
            <div className="table">
                <Table id="table-to-xls" >
                   
                <thead>
                    
                    <tr>
                        
                        <th className='tHeading'>Order#</th>
                        <th className='tHeading'>Date</th>
                        <th className='tHeading'>Store</th>     
                        <th className='tHeading'>Area</th>
                        <th className='tHeading'>Created At</th>
                       
                       

                        
                        
                    </tr>
                   
                </thead>
               

                <tbody className='heading2'>
                {processingOrders.map((val) => {
                    var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.postTime)
                    var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.postTime)
                    var date5 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.postTime)
                    return(

                        <tr>
                            <button onClick={toggle} className='tbutton'  value={val.orderNo} >{val.orderNo}</button>
                            <td className='tHeading2'>{date3}</td>
                            
                            <td className='tHeading2'>{val.store}</td>
                            <td className='tHeading2'>{val.location}</td>
                            
                           
                            <td className='tHeading2'>{date5}</td>
                           
                            
                        </tr>
                    )
                })}
               
                </tbody>
            </Table>
            </div>
           </div>



           <div className="transitOrders">
            <br />
            <p className='unplannedHeading'>Orders en route(Transit)</p>
            <div className="search">
                <p className='searchTitle'>Search:</p>
                <input type="text" className='searchInput' placeholder={records3} />
            </div>
            <div className="table">
                <Table id="table-to-xls" >
                   
                <thead>
                    
                    <tr>
                        
                        <th className='tHeading'>Order#</th>
                        <th className='tHeading'>Date</th>
                        <th className='tHeading'>Store</th>     
                        <th className='tHeading'>Area</th>
                        
                        <th className='tHeading'>Posted</th>
                       
                        <th className='tHeading'>Dispatched</th>
                        <th className='tHeading'>Rider</th>
                       

                        
                        
                    </tr>
                   
                </thead>
               

                <tbody className='heading2'>
                {transitOrders.map((val) => {
                    var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.postTime)
                    var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.postTime)
                    var date5 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.assignTime)
                    var date6 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.dispatchTime)
                    return(

                        <tr>
                            <button onClick={toggle2} className='tbutton'  value={val.orderNo} >{val.orderNo}</button>
                            <td className='tHeading2'>{date3}</td>
                            
                            <td className='tHeading2'>{val.store}</td>
                            <td className='tHeading2'>{val.location}</td>
                            
                            <td className='tHeading2'>{date4}</td>
                          
                            <td className='tHeading2'>{date6}</td>
                            <button className='tbutton' onClick={toggle3} value={val.rider} >{val.ridername}</button>
                            
                        </tr>
                    )
                })}
               
                </tbody>
            </Table>
            </div>
           </div>


           <div className="deliveredOrders">
            <br />
            <p className='unplannedHeading'>Orders Delivered Today</p>
            <div className="search">
                <p className='searchTitle'>Search:</p>
                <input type="text" className='searchInput' placeholder={records4} />
            </div>
            <div className="table">
                <Table id="table-to-xls" >
                   
                <thead>
                    
                    <tr>
                        
                        <th className='tHeading'>Order#</th>
                        <th className='tHeading'>Date</th>
                        <th className='tHeading'>Store</th>     
                        <th className='tHeading'>Area</th>
                        
                        <th className='tHeading'>Posted</th>
                       
                        <th className='tHeading'>Dispatched</th>
                        <th className='tHeading'>Delivered</th>
                        <th className='tHeading'>Rider</th>
                       

                        
                        
                    </tr>
                   
                </thead>
               

                <tbody className='heading2'>
                {deliveredOrders.map((val) => {
                    var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.postTime)
                    var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.postTime)
                    var date5 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.assignTime)
                    var date6 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.dispatchTime)
                    var date7 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.deliveryTime)
                    return(

                        <tr>
                            <button className='tbutton'  value={val.orderNo} >{val.orderNo}</button>
                            <td className='tHeading2'>{date3}</td>
                            
                            <td className='tHeading2'>{val.store}</td>
                            <td className='tHeading2'>{val.location}</td>
                            
                            <td className='tHeading2'>{date4}</td>
                            
                            <td className='tHeading2'>{date6}</td>
                            <td className='tHeading2'>{date7}</td>
                            <button className='tbutton' onClick={toggle3} value={val.rider}>{val.ridername}</button>
                            
                        </tr>
                    )
                })}
               
                </tbody>
            </Table>
            </div>
           </div>
       </div>
       <div className="ordersRight">
            <div className="totalOrdersBox">
                <p className='tOrders'>Total Orders today: {totalOrdersToday.length} </p>
                <div className="orderActivity">
                    <p className='aOrders'>Pending: {pendingOrders.length} </p>
                    <p className='aOrders'>In Dispatch: {processingOrders.length} </p>
                    <p className='aOrders'>In Transit: {transitOrders.length}</p>
                </div>
                <div className="orderActivity">
                <p className='aOrders'>Returned: {totalReturnedToday.length}</p>
                <p className='aOrders'>Cancelled: {totalCancelledToday.length}</p>
                <p className='aOrders'>Delivered: {deliveredOrders.length}</p>

                </div>
                
            </div>

            <div className="totalOrdersBox">
            <p className='tOrders'>Orders by Store </p>
                <div className="storeActivity">
                    <p className='aOrders'>Naivas Prestige: {prestigeOrdersToday.length} </p>
                    <p className='aOrders'>Naivas Kilimani: {kilimaniOrdersToday.length} </p>
                    <p className='aOrders'>Naivas Waterfront: {waterfrontOrdersToday.length}</p>
                </div>
                
                
            </div>

            <div className="totalOrdersBox">
            <p className='tOrders'>Orders by Rider </p>
                <div className="storeActivity">
                {riderByOrder.map((val) => {
                    
                    return(
                        <>
                       <p className='aOrders'>{val.ridername}: {val.totalOrders} </p>
                      
                        </>
                    )
                })}
                    
                </div>
                
                
            </div>
       </div>
    </div>
</div>


 {/***********Contact Add Box**************/}
 <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>Dispatch Order</ModalHeader>
        <Dispatch orderno={thisOrder} click={toggle} />
      </Modal>
      {/***********Contact Add Box End**************/}

   {/***********Contact Add Box**************/}
 <Modal isOpen={modal2} toggle={toggle2} size="md">
        <ModalHeader toggle={toggle2}>Complete/Reassign Order {thisOrder}</ModalHeader>
        <Transit orderno={thisOrder} click={toggle} />
      </Modal>
      {/***********Contact Add Box End**************/}

      <Modal isOpen={modal3} toggle={toggle3} size="md">
        <ModalHeader toggle={toggle3}>Rider Details</ModalHeader>
        <Riderdetails thisRider={thisRider} click={toggle} />
      </Modal>
      {/***********Contact Add Box End**************/}
</div>
  )
}
